import invariant from 'invariant';

import {
  CmsApiCmsPageLink,
  CmsApiLinkType,
  CmsApiUrlLink,
} from 'modules/cms/api/types/CmsApiLink';
import { trackClicked } from 'utils/analytics/track/trackClicked';

import { CmsButtonRender } from './types/CmsButtonRender';

type Props = {
  buttonData: CmsApiUrlLink | CmsApiCmsPageLink;
  eventProperties: Record<string, unknown>;
  render: CmsButtonRender;
};

export function CmsLinkButton({ eventProperties, buttonData, render }: Props) {
  const { url } = buttonData.data;
  invariant(url, 'Button url is not defined');

  const onClick = () => {
    trackClicked('CMS Block', {
      url,
      ...eventProperties,
    });
  };

  const title =
    buttonData.type === CmsApiLinkType.CMS_PAGE
      ? buttonData.data.pageTitle
      : undefined;

  return render({
    data: { type: 'link', href: url, onClick },
    children: (
      <div style={{ display: 'flex', alignItems: 'center' }}>
        {buttonData.text}
      </div>
    ),
    title,
    'data-qa-id': 'cms-button-link',
  });
}
